import IndyFoodImage from "./Images/Indy-Food.png";
import SpacetagramImage from "./Images/Spacetagram.png";
import GetItDoneImage from "./Images/GetItDone.png";
import VerticalScrambleImage from "./Images/Vertical-Scramble.png";

export let projects = [
	{
		title: "Vertical Scramble",
		image: VerticalScrambleImage,
		skills: [
			"React JS",
			"Redwood JS",
			"Typescript",
			"HTML",
			"Git",
			"Styled Components",
			"Figma",
			"Netlify",
			"Storybook",
		],
		description: "",
		liveLink: "https://verticalscramble.com",
		idName: "vertical-scramble",
	},
	{
		title: "Indy Food",
		image: IndyFoodImage,
		skills: [
			"Javascript",
			"Redwood JS",
			"React JS",
			"Git",
			"SASS",
			"GraphQL",
			"Figma",
			"Netlify",
			"Storybook",
			"HTML",
		],
		description: "",
		liveLink: "https://vocal-liger-00206f.netlify.app/",
		githubLink: "https://github.com/caseymockbee97/indy-food-blog",
		idName: "indy-food",
	},
	{
		title: "Spacetagram",
		image: SpacetagramImage,
		skills: [
			"React JS",
			"Material UI",
			"Javascript",
			"HTML",
			"Git",
			"CSS",
			"Figma",
			"Affinity Designer",
			"Github Pages",
		],
		description: "",
		liveLink: "https://caseymockbee97.github.io/spacetagram/",
		githubLink: "https://github.com/caseymockbee97/spacetagram",
		idName: "spacetagram",
	},
	{
		title: "GetItDone.js",
		image: GetItDoneImage,
		skills: [
			"React JS",
			"Node.js",
			"Javascript",
			"Express",
			"HTML",
			"Git",
			"CSS",
			"zustand",
			"react-beautiful-dnd",
			"Github Pages",
			"Semantic UI",
		],
		description: "",
		liveLink: "https://caseymockbee97.github.io/kapstone19/",
		githubLink: "https://github.com/caseymockbee97/kapstone19",
		idName: "get-it-done",
	},
];
